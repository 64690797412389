<template>
  <CFooter :fixed="false" style="justify-content: end;">
    <div>
      <span class="ml-1"> {{$t('developed.by')}} </span>
      <a href="" target="_blank">Softico C.A.</a>
      <span class="ml-1">&copy; {{new Date().getFullYear()}} .</span>
    </div>
  </CFooter>
</template>

<script>
export default {
  name: 'TheFooter'
}
</script>
