<template>
  <div>
    <v-select 
      :options="langOptions" 
      :clearable="false" 
      v-model.trim="localSelect"
    >
      <template #selected-option="{ label, icon }">
        <div class="d-flex align-items-center">
          <CIcon :name="icon" class="ml-2 mr-2"/>
          <strong>{{ $t('label.lang') }}</strong>
        </div>
      </template>
      <template #option="option" >
        <CIcon :name="option.icon"/>
        {{ option.label }}
      </template>
    </v-select>
  </div>
</template>

<script>
import DataStorage from '@/_helpers/localStorage';

export default {
  name: 'locale',
  created() {
    if(DataStorage.getLocale()){
      let local = DataStorage.getLocale();
      this.localSelect = Object.assign({},{
        label: local,
        value: local,
        icon: local == 'es'? 'cif-es' : 'cif-gb'
      });
    } else {
      let local = window.navigator.language.split('-')[0];
      if(this.langs.includes(local)){
        this.localSelect = Object.assign({},{
          label: local,
          value: local,
          icon: local == 'es'? 'cif-es' : 'cif-gb'
        });
      }else {
        this.localSelect = Object.assign({},{
          label: 'es',
          value: 'es',
          icon: 'cif-es'
        });
      }
    }
  },
  data() {
    return { 
      langs: ['en', 'es'],
      langOptions: [
        {
          label: 'en',
          value: 'en',
          icon: 'cif-gb'
        },
        {
          label: 'es',
          value: 'es',
          icon: 'cif-es'
        }
      ],
      localSelect: { label: 'es', value: 'es', icon: 'cif-es' }
    }
  },
  watch: {
    localSelect: function(newValue) {
      if(newValue){
        this.$i18n.locale = newValue.value;
        DataStorage.setLocale(newValue.value);
        this.$store.dispatch('menu/remakeMenu', this.$i18n.locale);
      }
    }
  }
}
</script>

<style scoped>
</style>